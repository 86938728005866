@import "~@arctravel/react-fds/lib/designTokens/color.scss";
@import "~@arctravel/react-fds/lib/designTokens/theme.scss";

$night-d-bg: $night-teal-800;
$night-l-bg: $night-teal-600;

body.night {
    #arccidsWrap {
        position: relative;
        //top: -48px;
    }

    .floatFiltersWrap {
        .arrowContent,
        .card {
            background-color: $night-d-bg;
        }
        .close {
            color: white;
        }
    }

    .fds-pl-container1[class*="ARCCIDS"] {
        background-image: url("../public/ci-bg.png"), var(--theme-background);
        background-size: 100% 1000px;
        background-repeat: repeat-x;
        background-blend-mode: overlay;
        min-height: calc(100vh - 311px);
    }

    .fds-pl .fds-pl-primary {
        background: transparent;
    }

    .reportingPage {
        .fds-pl .fds-pl-column,
        .leftWrap {
            background: transparent;
        }
        .leftWrap {
            padding: 1rem;
        }
        .darkbg {
            background: transparent;
        }
        .blankable.darkbg {
            background: $night-d-bg;
        }
    }

    .ARCCIDS {
        .reportingPage {
            #resultsWrap {
                .card {
                    border: none;
                }
            }
        }
    }

    .arrowList .list-group-item {
        background: $night-d-bg;
        &.active {
            background: $night-l-bg;
        }
        &::after {
            display: none;
        }
    }
}

body.night {
    .accordion {
        border: none;
        .accordion-header,
        .accordion-header button,
        .accordion-item {
            background: rgba(244, 244, 244, 0.05);
            color: white !important;
            border: none;
        }
    }
}

.dp-link {
    color: #1f9cb1 !important;
}


.night .ciDetailWrap {
    background-color: $night-purple-800 !important;
    color: white;
    * {
        color: white !important;
    }
}