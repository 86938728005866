@import "~@arctravel/react-fds/lib/designTokens/color.scss";
@import "../../../night.scss";

#arccidsWrap {
    .draChart .chartSection {
        background: transparent;
    }
    .clearAll {
        text-decoration: none !important;
        color: #ffffff99;
    }
}

.arcciCorpWrap {
    border-bottom: none;
    table {
        width: 100%;
        thead {
            th {
                input,
                select {
                    width: 100% !important;
                    max-width: 100%;
                    min-height: 1.5rem !important;
                    height: 1.5rem !important;
                    padding: 0 2px;
                }
                .react-datepicker__input-container input {
                    min-width: 150px;
                }
                .fds-datetime-range {
                    input {
                        width: 100% !important;
                        min-height: 10px !important;
                        font-size: 12px;
                        padding: 0 2px;
                    }
                }
            }
            th:nth-child(2),
            th:nth-child(3) {
                input {
                    width: 100% !important;
                }
            }
        }
    }
    .tcvIcon {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 1.4rem;
    }
    .dot.undefined {
        background-color: white;
    }
    .bg-dark {
        background-color: black;
    }
    .initialWrap {
        max-width: 48px;
        padding-top: 9px;
    }
    .ciRow {
        padding: 0.5rem;
        border-bottom: 1px solid #ccc;
        .contactWrap {
            .shortName {
                margin-top: 0.6rem;
            }
            .lessMargin {
                margin-top: -2px;
            }
        }
    }
    .tmcRight * {
        white-space: break-spaces;
    }
    &.tmc {
        border: 1px solid #ccc;
        .tmcLeft {
            padding: 0.5rem;
            background: #f0f0f5;
            border-right: 1px solid #ccc;
            .dot {
                margin-top: 12px;
            }
        }
        .tmcRight {
            padding: 0.5rem;
        }
    }

    div[role="button"]:hover,
    &[role="button"]:hover,
    &.boxShadow:hover {
        box-shadow: 0px 0px 5px $primary !important;
    }
}

.tcvModal {
    .modal-dialog {
        max-width: 90%;
    }
}

.dataStatus {
    .row {
        padding: 0.5rem 0;
    }
    .header {
        border-bottom: 1px solid white;
    }
}

@keyframes example {
    from {
        width: 0;
    }
    to {
        width: 70%;
    }
}

.ciDetailWrap {
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    z-index: 20;
    padding: 1rem;
    background-color: #0a131f !important;
    animation-name: example;
    animation-duration: 0.35s;
    .close {
        position: absolute;
        right: 1rem;
        top: 0;
        font-size: 2rem;
        cursor: pointer;
    }
}

ul.tickList {
    padding-left: 5px;
    li {
        list-style: none;
        position: relative;
        padding: 0 1.3rem 8px 1.3rem;
        &::before {
            content: "✓";
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        &.circle {
            font-weight: 600;
        }
        &.circle::before {
            border: 2px solid;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            padding: 0px 0 0 4px;
            margin-top: 4px;
            font-size: 11px;
            margin-left: -5px;
            font-weight: 900;
        }
        &.circle {
            &.success::before {
                border-color: $success;
                color: $success;
            }
            &.danger::before {
                border-color: $danger;
                color: $danger;
            }
        }
        &:not(:last-child)::after {
            content: "|";
            position: absolute;
            left: 3px;
            top: 24px;
            z-index: 1;
        }
    }
}

.draChart {
    svg {
        text[fill="teal"] {
            font-size: 2.5rem !important;
            dominant-baseline: auto !important;
        }
        text[fill="black"] {
            font-size: 5rem !important;
            dominant-baseline: mathematical !important;
        }
    }
}
