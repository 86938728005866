.dropdown {
  background: transparent;

  .dropdown-toggle {
    white-space: pre-line;

    &:after {
      color: #fff;
      font-size: 0.8em;
      vertical-align: 0;
    }

    &.btn-lg {
      &:after {
        top: 15px;
      }
    }
  }
}

.prevNextSpace {
  //min-height: 2rem;
}

.topRunButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.floatFiltersWrap {
  position: absolute;
  top: -1rem;
  z-index: 100;
  width: calc(100% - 1.25rem);
  margin-left: -3.5rem;
  box-shadow: 0px 0px 2px white;
  .card {
    border: none;
  }
  .close {
    position: absolute;
    right: 0px;
    z-index: 1;
    font-size: 2rem;
    cursor: pointer;
    top: -8px;
  }
}

.filtersToggleBtn {
  position: absolute;
  top: 16px;
  right: 200px;
  z-index: 1;
}
