@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.proprietaryfields {
    background-color: #d2f2f7;
}

.cursor {
    cursor: pointer;
}

.collapse.show + .card-header {
    i.cursor {
        transform: rotate(180deg);
        display: inline-block;
    }
}

.tagWrap {
    max-height: 250px;
    overflow: auto;
    height: auto;
    .badge {
        margin-left: 1px;
    }
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    z-index: 10 !important;
}

.viewall {
    color: $primary;
    font-weight: 600;

    &:hover {
        text-decoration: underline;
    }
}

button.viewall {
    font-weight: normal;

    &:hover {
        text-decoration: none;
    }
}

.nav-tabs[scoped] {
    .nav-item {
        border-right: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        &:focus {
            border-top: none;
            background-color: $gray-200;
            border-color: $gray-300;
            text-decoration: underline;
            color: $primary;
        }
        &.active {
            top: 0;
            border-bottom: 1px solid transparent;
            &:focus {
                background-color: $white;
            }
        }
    }
}
.formName {
    font-weight: 600;
    font-size: 1rem;
}

.accordion {
    .card {
        .card-header {
            border-bottom: none;
        }
        .collapse + .card-header:after,
        .collapsing + .card-header:after {
            display: none;
        }

        i {
            color: $primary;
            position: relative;
            top: 2px;
        }
    }
}

.template-block {
    position: relative;
    .fds-typeahead,
    .react-dropdown-tree-select,
    .react-datepicker__input-container,
    .select,
    input[type="text"],
    select {
        max-width: 100%;
        width: 100%;
    }
    tr {
        display: flex;
        td.moreOptionsWrap {
            width: 30%;
        }
        td.moreOptionsWrap + td {
            width: 70%;
        }
    }
    &.individualWrap {
        //width: 50%;
    }
    &.mergeWraps {
        width: 100%;
    }
    .tab-content {
        tr.single {
            display: grid;
        }
        tr.more {
            .fds-typeahead,
            .react-dropdown-tree-select {
                //width: 300px !important;
            }
            .fds-datetime-range {
                //width: 350px !important;
            }
            .react-dropdown-tree-select ul.tag-list li.tag-item:last-child {
                position: relative;
            }
        }
    }
    .card-header {
        background: none;
    }
    .exclude {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        .excludeIcon {
            font-size: 20px;
        }
        span {
            position: relative;
            top: -3px;
        }
        &.option {
            &.notitled {
                top: -30px;
            }
        }
    }
}

form .fds-glyphs-info:before {
    font-size: 1rem;
}

.form-switch {
    display: inline-block;
    .form-check-input {
        margin-top: 0;
    }
}

.row > span {
    width: auto;
}

// TODO: Move to FDS
.custom-switch.form-switch {
    padding: 0;
    position: relative;
    top: -5px;
    &.primary {
        .form-check-input:checked ~ .form-check-label::before {
            border-color: $primary;
            background-color: $primary;
        }
    }
    &.danger {
        .form-check-input:checked ~ .form-check-label::before {
            border-color: $danger;
            background-color: $danger;
        }
    }
    &.success {
        .form-check-input:checked ~ .form-check-label::before {
            border-color: $success;
            background-color: $success;
        }
    }
    &.warning {
        .form-check-input:checked ~ .form-check-label::before {
            border-color: $warning;
            background-color: $warning;
        }
    }
    &.info {
        .form-check-input:checked ~ .form-check-label::before {
            border-color: $info;
            background-color: $info;
        }
    }
    &.enabled {
        .form-check-input:checked ~ .form-check-label::before {
            border-color: $teal-arc-brand;
            background-color: $teal-arc-brand;
        }
    }
    .form-check-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1.5rem;
        height: 1.5rem;
        opacity: 0;
        padding: 0;
        overflow: visible;
    }

    .form-check-input:checked ~ .form-check-label::after {
        transform: translateX(1.5rem);
    }

    .form-check-label {
        padding-left: 0;
        position: relative;
        margin-bottom: 0;
        vertical-align: middle;
        background: none !important;
        display: inline-block;

        &::before {
            color: $white;
            background-color: $gray-500;
            border: $gray-500;
            content: "\e924";
            padding-top: 5px;
            padding-right: 7px;
            font-size: 0.6rem;
            text-align: right;
            width: 3rem;
            height: 1.5rem;
            pointer-events: all;
            border-radius: 0.75rem;
            display: inline-block;
            margin-right: 10px;
            transition:
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }

        &::after {
            content: "";
            position: absolute;
            background: 50%/50% 50% no-repeat;
            left: 2px;
            top: 4px;
            width: calc(1.5rem - 4px);
            height: calc(1.5rem - 4px);
            border-radius: 0.75rem;
        }
    }

    .form-check-input:checked ~ .form-check-label::before {
        border-color: $teal-500;
        background-color: $teal-500;
        content: "\ed71 ";
        padding-left: 7px;
        text-align: left;
    }

    &.custom-switch-on-off-unmask {
        .form-check-label::before {
            font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
            content: "Mask";
            width: 4.3rem;
        }

        .form-check-input:checked ~ .form-check-label::before {
            content: "Unmask";
        }

        .form-check-input:checked ~ .form-check-label::after {
            background-color: $white;
            transform: translateX(2.8rem);
        }
    }
    &.custom-switch-on-off {
        .form-check-label::before {
            font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
            content: "Exclude";
            width: 4.3rem;
        }

        .form-check-input:checked ~ .form-check-label::before {
            content: "Excluded";
        }

        .form-check-input:checked ~ .form-check-label::after {
            background-color: $white;
            transform: translateX(2.8rem);
        }
    }

    .form-check-input:hover:not([disabled]) ~ .form-check-label::before {
        background-color: $teal-500B;
        border-color: $teal-500B;
    }

    .form-check-input:checked:hover:not([disabled]) ~ .form-check-label::before {
        background-color: $teal-400;
        border-color: $teal-400;
    }

    .form-check-label::after {
        background-color: $white;
    }

    .form-check-input:focus ~ .form-check-label::before {
        box-shadow:
            0 0 0 1px $white,
            0 0 0 3px $teal-400;
    }
}

html .card.enable-border {
    border: none !important;
}

.react-datepicker__quarter-text--today {
    background: #216ba5 !important;
}

.hideCheckbox {
    &::before {
        content: " ";
        width: 80%;
        height: 2rem;
        display: block;
        background-color: transparent;
        margin-right: 5px;
        position: absolute;
        margin-left: 27px;
        margin-top: -5px;
    }
    &::after {
        content: " ";
        width: 0.7rem;
        height: 0.7rem;
        display: block;
        background-color: #949494;
        position: absolute;
        margin-left: 27px;
        margin-top: -17px;
        border-radius: 2px;
        border: 3px solid #ccc;
    }
    label {
        padding-left: 16px;
    }
    .checkbox-item {
        display: none;
    }
}

.react-dropdown-tree-select.data_metrics,
.react-dropdown-tree-select.rows,
.react-dropdown-tree-select.cols {
    .dropdown .dropdown-content {
        max-height: 330px;
    }
}

label.selection {
    font-weight: 600;
}
.watermark {
    position: absolute;
    font-size: 4rem;
    right: 3rem;
    bottom: 4rem;
    opacity: 0.1;
    cursor: default;
    transform: rotate(-15deg);
}

.accordianWrap {
    .accordianHead,
    .accordianHead button {
        font-size: 2rem;
    }
}
