@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.modal-body {
  .row {
    margin-bottom: 15px;
  }
  .react-datepicker-wrapper {
    display: block;
  }
}

.modal .modal-dialog {
  max-width: 400px;
}

.modal .modal-content {
  max-height: 90vh;
  .modal-body {
    max-height: 600px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.modal .modal-content .modal-body::-webkit-scrollbar {
  display: none;
}

.modal-height {
  height: 650px;
}

.modal-body .feedBackBtn .me-2.btn.active.btn-secondary{
  background-color: $teal-600;
  color: white;
}

#feedBackBtn {
  position: relative;
  text-align: right;
  height: 0;
  margin-right: 0;
  .rounded-0 {
    right: 3rem;
    z-index: 10;
    background-color: $teal-600;
  }
}

.feedBackBtn {
  button {
    padding: 0.4rem 1rem;
  }
}

#messages{
  min-height: 8rem !important;
}

#userModal {
  .fds-tag {
    text-transform: capitalize;
  }
}
